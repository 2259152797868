import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const state = {
    clientWidth: 100 + '%',
    privacyPolicy:null
}

const mutations = {
    receiveClientWidth(state, payload) {
        // 将用户的设备宽度数据存放于state
        state.clientWidth = payload.clientWidth
    },
    getprivacyPolicy(state, payload){
        state.privacyPolicy = payload.privacyPolicy
    }
}

export default new Vuex.Store({
    state,
    mutations
})
