import Vue from 'vue'
import Router from 'vue-router'
import soleHome from '../views/soleHome.vue'

Vue.use(Router)


const routes = [{
    path: "/",
    name: "soleHome", //默认显示推荐组件(路由的重定向)
    component: soleHome
},
{
    path: "/soleAdvertiser",
    name: "soleAdvertiser",
    component: () => import('../views/soleAdvertiser')
},
{
    path: "/solePublisher",
    name: "solePublisher",
    component: () => import('../views/solePublisher')
},
{
    path: "/soleAboutus",
    name: "soleAboutus",
    component: () => import('../views/soleAboutus')
},
{
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: () => import('../views/privacyPolicy')
},
{
    path: "/termsOfUse",
    name: "termsOfUse",
    component: () => import('../views/termsOfUse')
}

    // {
    //     path: "*",
    //     component: NotFound //定义找不到已有组件时显示404
    // },
]

// 创建路由对象并且传入规则
const router = new Router({
    mode: 'history',
    // history: Router.createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // return 期望滚动到哪个的位置
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    }
})




// const router = Router.createRouter({
//     // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
//     history: Router.createWebHashHistory(),
//     routes, // `routes: routes` 的缩写
// })


// const router = Router.createRouter({
//     // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
//     history: Router.createWebHashHistory(),
//     routes, // `routes: routes` 的缩写
// })
export default router

// export default router.beforeEach
