<template>
  <div id="app">
    <soleHeader v-show="isHide"></soleHeader>
    <router-view></router-view>
    <soleBottom v-show="isHide"></soleBottom>
    <soleBackTop v-show="isShow"></soleBackTop>
  </div>
</template>

<script>
  import soleHeader from './components/soleHeader.vue'     // 顶部导航栏组件
  import soleBottom from './components/soleBottom.vue'     // 底部栏组件
  import soleBackTop from './components/soleBackTop.vue'   // 一键回顶组件
  export default {
    name: 'App',
    components: {
      soleHeader,
      soleBottom,
      soleBackTop
    },
    data() {
      return {
        isShow: false,
        clientWidth: 0,
        isHide: true
      }
    },
    mounted() {
      window.addEventListener('scroll', this.windowScroll) //监听页面滚动
      this.watchWidth()    // 获取设备的宽度
    },
    destroyed() {
      window.removeEventListener("scroll", this.windowScroll)//销毁滚动事件
    },
    computed: {
      detectionPrivacyPolicy() {
        return this.$store.state.privacyPolicy
      }
    },
    watch: {
      detectionPrivacyPolicy(newVal, oldVal) {
        //特别注意，不能用箭头函数，箭头函数，this指向全局
        console.log('是否打开了隐私政策',oldVal);
        if (newVal) {
          this.isHide = false
        }else{
          this.isHide = true
        }
      }
    },
    methods: {
      // 监听页面滚动 获取滚动条位置
      windowScroll() {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        if (scrollTop > 100) {
          this.isShow = true
        } else {
          this.isShow = false
        }
      },
      // 窗口的宽度
      watchWidth() {
        window.onresize = () => {
          return (() => {
            window.clientWidth = document.body.clientWidth
            this.clientWidth = window.clientWidth
            // console.log('设备宽度', this.clientWidth)
            // 获取到设备的宽度并存储到store
            this.$store.commit('receiveClientWidth', {
              clientWidth: this.clientWidth
            })
          })()
        }

      },

    }
  }
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    margin: 0;
    padding: 0;
  }
</style>