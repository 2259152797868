<template>

    <div class="conteair" :style="`width: ${clientWidth}px;`">
        <a href="/"><h1 class="logo">Orange AD</h1></a>
        <a v-show="conceal" class="title" href="/">HOME</a>
        <router-link v-show="conceal" class="title" to="/soleAdvertiser">ADVERTISER</router-link>
        <router-link v-show="conceal" class="title" to="/solePublisher">PUBLISHER</router-link>
        <router-link v-show="conceal" class="title" to="/soleAboutus">ABOUT US</router-link>
        <el-button v-show="!conceal" class="button" type="primary" @click="checkBoxShow"><i
                class="el-icon-menu"></i></el-button>
        <div :class="`choiceBox ${inAnimation ==='second'?'anim':inAnimation==='thirdly'?'animNone':''}`">
            <a class="title" v-show="!conceal" href="/">HOME</a>
            <a class="title" v-show="!conceal" href="/soleAdvertiser">ADVERTISER</a>
            <a class="title" v-show="!conceal" href="/solePublisher">PUBLISHER</a>
            <a class="title" v-show="!conceal" href="/soleAboutus">ABOUT US</a>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'soleHeader',
        data() {
            return {
                conceal: false,
                newWidth: 0,
                inAnimation: 'first',
                windowWidth: document.documentElement.clientWidth  // 实时获取用户设备的宽度
            }
        },
        mounted() {
            // 页面一加载根据页面来判断是移动还是pc端
            if (this.windowWidth > 768) {
                this.conceal = true
            } else if (this.windowWidth < 768) {
                this.conceal = false
            }
        },
        methods: {
            // 点击展开下拉选择器
            checkBoxShow() {
                if (this.inAnimation === 'first' || this.inAnimation === 'thirdly') {
                    this.inAnimation = 'second'
                } else if (this.inAnimation === 'first' || this.inAnimation === 'second') {
                    this.inAnimation = 'thirdly'
                }
            }
        },
        computed: {
            clientWidth() {
                // 这里存储从store里获取的设备的宽度
                // console.log('这里存储从store里获取的设备的宽度', this.$store.state.clientWidth)
                // this.newWidth = this.$store.state.clientWidth
                return this.$store.state.clientWidth
            }
        },
        watch: {
            clientWidth(newVal, oldVal) {
                console.log('oldVal', oldVal)
                console.log('newVal', newVal)
                if (newVal > 768) {
                    this.conceal = true
                } else if (newVal < 768) {
                    this.conceal = false
                }
            }
        }
    }
</script>


<style lang="less" scoped>
    .conteair {
        max-width: 1920px;
        width: 100%;
        height: 108px;
        max-height: 108px;
        padding: 0 30px;
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        z-index: 9999;
        animation: show 0.5s ease-out 1;
        animation-fill-mode: both;
        background-color: #ffffff;

        .logo {
            position: absolute;
            left: 30px;
            top: 50%;
            transform: translate(0, -50%);
            cursor: pointer;
            background: linear-gradient(to right, #ff7f02, #ffc602);
            background-clip: text;
            -webkit-background-clip: text;
            /* 设置文本颜色透明以露出后面裁切成文本形状的渐变背景 */
            color: transparent;
        }
    }

    .title {
        font-weight: 600;
        margin-right: 35px;
        cursor: pointer;
        text-align: center;
        font-size: 15px;
        color: #3f3a64;
        font-family: "Kumbh Sans", sans-serif;
    }

    .title:hover {
        color: orange;
    }

    .button {
        width: 44px;
        height: 31px;
        background-color: orange;
        border-color: orange;
        padding: 0px 0px;
    }

    /* 显示动画 */
    @keyframes show {
        0% {
            /*使用background-position制作动画效果*/
            height: 0px;
            opacity: 0;
        }

        100% {
            /*移动位置由图片实际大小决定*/
            height: 108px;
            opacity: 1;
        }
    }

    @media screen and (max-width: 768px) {
        .conteair {
            height: 68px;
            max-height: 68px;
            width: 100%;
            padding: 10px 10px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
        }

        .el-icon-menu {
            font-size: 20px;
            line-height: 1.5;
        }

        .button {
            width: 44px;
            height: 31px;
            background-color: orange;
            border-color: orange;
            padding: 0px 0px;
        }

        .choiceBox {
            position: absolute;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            top: 0;
            left: 0;
            background-color: #ffffff;
            padding-left: 30px;
            padding-top: 60px;
            box-sizing: border-box;
            z-index: -1;
            height: 0;
            overflow: hidden;

            .title {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 15px;
                cursor: pointer;
            }


        }

        .anim {
            animation: choiceBoxShow 0.5s linear;
            animation-fill-mode: forwards
        }

        @keyframes choiceBoxShow {
            0% {
                height: 0px;
            }

            100% {
                height: 200px;
            }
        }

        .animNone {
            animation: choiceBoxHide 0.5s linear;
            animation-fill-mode: forwards
        }

        @keyframes choiceBoxHide {
            0% {
                height: 200px;
            }

            100% {
                height: 0px;
            }
        }
    }
</style>