<template>
    <div class="conteair">
        <div class="bag_01" :style="`width:${clientWidth}px;`"><img class="img_01" src="../assets/index_bag_01.png">
        </div>
        <!-- 滚动轮播图 -->
        <div :style="`width: ${clientWidth}px;overflow:hidden;`">
            <vue-seamless-scroll :data="swiperList" :class-option="optionLeft" class="seamless-warp"
                :style="`width:${clientWidth}px;max-width: 888px;`">
                <ul class="item" style="width: 100%;">
                    <li v-for="item in swiperList" :key="item.index" class="item_li">
                        <img :src="item.img" alt="">
                    </li>
                </ul>
            </vue-seamless-scroll>
        </div>
        <!--  -->
        <div :style="`width: ${clientWidth}px;max-width: 1920px;`">
            <a href="/soleAdvertiser"><img src="../assets/index_bag_02.png" alt="" class="backgaoundColor"></a>
        </div>
        <!--  -->
        <div :style="`width: ${clientWidth}px;max-width: 1920px;`">
            <img src="../assets//index_bag_03.png" alt="" class="backgaoundColor">
        </div>
        <!--  -->
        <div :style="`width: ${clientWidth}px;max-width: 1920px;`">
            <a href="/solePublisher"><img src="../assets/index_bag_04.png" alt="" class="backgaoundColor"></a>
        </div>
        <!--  -->
        <div :style="`width: ${clientWidth}px;max-width: 1920px;`">
            <img src="../assets//index_bag_05.png" alt="" class="backgaoundColor">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'soleHome',
        data() {
            return {
                swiperList: [
                    { img: '/image/swiper_01.png' },
                    { img: '/image/swiper_02.png' },
                    { img: '/image/swiper_03.png' },
                    { img: '/image/swiper_04.png' },
                    { img: '/image/swiper_05.png' },
                    { img: '/image/swiper_06.png' },
                ]
            }
        },
        computed: {
            optionLeft() {
                return {
                    show: false,
                    autoPlay: true,
                    step: 0.7, //速度
                    direction: 2, //滚动方向
                    limitMoveNum: 6, //多少数量滚动
                    hoverStop: true,
                    openTouch: true,
                    openWatch: true,
                }
            },
            clientWidth() {
                // 这里存储从store里获取的设备的宽度
                // console.log('这里存储从store里获取的设备的宽度', this.$store.state.clientWidth)
                return this.$store.state.clientWidth
            }
        }
    }
</script>

<style lang="less" scoped>
    .bag_01 {
        width: 100%;
        /* height: 1146px; */
        max-height: 1146px;
        max-width: 1920px;

        .img_01 {
            width: 100%;
            height: 100%;
        }
    }

    .backgaoundColor {
        width: 100%;
        height: 100%;
    }

    /* 轮播图 */
    .seamless-warp {
        /* height: 92px; */
        overflow: hidden;
        margin: 50px auto;
    }

    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        box-sizing: border-box;
    }

    .item_li {
        width: 132px;
        /* max-width: 132px; */
        height: 90px;
        /* max-height: 90px; */
        margin: 0px 35px;
        border: 1px solid transparent;
        box-sizing: border-box;
    }

    .item_li>img {
        width: 100%;
        height: 100%;
    }

    .item_li:hover {
        border: 1px solid #023246;
        box-shadow: 6px 0px 20px #eeeeee;
        box-shadow: 0px 6px 20px #dadada;
    }

    .item_li {
        transition: all 0.4s ease 0s;
    }
</style>