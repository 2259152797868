<template>
    <div class="conteair" :style="`width:${clientWidth}px;`">
        <div class="footer-btm" :style="`width:${clientWidth}px;`">
            <div><a href="/privacyPolicy" class="textlink">PRIVACY POLICY</a><a href="/termsOfUse" style="margin-left: 20px;"
                    class="textlink">TERMS OF SERVICE</a></div>
            <span class="textlink">@2023 orange.sc.cn- All Rights Reserved</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'soleBottom',
        data() {
            return {

            }
        },
        computed: {
            clientWidth() {
                // 这里存储从store里获取的设备的宽度
                // console.log('这里存储从store里获取的设备的宽度', this.$store.state.clientWidth)
                // this.newWidth = this.$store.state.clientWidth
                return this.$store.state.clientWidth
            }
        },
    }
</script>

<style scoped>
    .conteair {
        width: 100%;
        max-width: 1920px;
        height: 68px;
        max-height: 68px;
        /* margin-top: 70px; */
        box-sizing: border-box;
        border-top: 1px solid #ededed;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 100px 0 100px;
    }

    .footer-btm {
        width: 1140px;
        max-width: 1140px;
        height: 27px;
        max-height: 27px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .textlink {
        font-family: 500;
        font-size:calc(100vw * 12 / 1920);
        font-family: 'Quicksand', sans-serif;
        text-decoration: none;
        color: orange;
        background-color: transparent;
    }
    /* 宽度小于768以下的以移动端处理 */
    @media screen and (max-width: 768px) {
        .conteair{
            padding: 0;
        }
        .footer-btm {
            width: 1140px;
            max-width: 1140px;
            height: 27px;
            max-height: 27px;
            display: flex;
            justify-content: space-around;
            align-items: center;
        }
        .textlink {
        font-family: 500;
        font-size:calc(100vw * 8 / 1920);
        font-family: 'Quicksand', sans-serif;
        text-decoration: none;
        color: orange;
        background-color: transparent;
    }
    }
</style>