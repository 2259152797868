import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router/index'
import store from './store'
import scroll from 'vue-seamless-scroll'  // 通过npm下载的第三方滚动插件


Vue.use(scroll)                           // 注册插件
Vue.use(ElementUI)
Vue.use(router)
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
