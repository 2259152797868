<template>
    <div class="backTop" @click="goTop">
        <div class="icon-share"></div>
    </div>
</template>
<script>
    export default {
        data() {
            return {

            }
        },
        methods: {
            // 一键回顶功能
            goTop() {
                let timer
                timer = setInterval(function () {
                    let osTop = document.documentElement.scrollTop || document.body.scrollTop
                    let ispeed = Math.floor(-osTop / 2.5)
                    document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
                    if (osTop < 200) {
                        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed * 3
                    }
                    if (osTop === 0) {
                        clearInterval(timer)
                    }
                }, 25)
            },
        },
    }
</script>
<style>
    .backTop {
        background-color: orange;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: fixed;
        right: 40px;
        bottom: 40px;
        text-align: center;
        line-height: 50px;
    }

    .icon-share::before {
        content: "";
        width: 5px;
        height: 5px;
        border: solid #fff;
        border-width: 3px 0 0 3px;
        transform: translate(-50%, -50%) rotate(45deg);
        position: absolute;
        left: 50%;
        top: 50%;
    }
</style>